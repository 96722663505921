body {
  margin: 0;
}

#root{
  height: 100vh;
}

.trianglify {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -100;
}
